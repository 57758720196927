<template>
	<div
		class="px-4 pt-4 z-[200] h-screen overflow-y-auto fixed top-0 left-1/2 bg-app-blue md:max-w-[592px] -translate-x-1/2 w-full"
		v-if="displayModal"
	>
		<div class="relative">
			<DefaultButton
				color="transparent"
				size="fit"
				class="mb-6 w-full justify-end flex"
				itemPosition="none"
				@click="closeModal"
			>
				<span class="material-symbols-outlined text-[32px]">close</span>
			</DefaultButton>
			<div class="mb-4">
				<h2 class="text-2xl leading-8 font-bold mb-4 text-center">
					{{ $t('subscribeOfferModal.title') }}
				</h2>
				<img
					src="https://cdn.realitystudio.ai/assets/web/presets-and-tools/subscribe-offer-modal.jpg"
					alt="subscribe-banner"
					class="w-full object-cover mb-4 rounded-xl"
				/>
				<p class="text-lg leading-7 text-center">
					{{ $t('subscribeOfferModal.description') }}
				</p>
			</div>
			<div class="mb-6">
				<div
					v-for="(benefit, index) in benefits"
					:key="index"
					class="rounded-xl mb-4 border border-radial-1-card"
				>
					<div
						:class="{
							'rounded-xl bg-gradient-to-r from-lime-500 via-purple-500 to-cyan-500 border-padding':
								benefit.highlight,
						}"
					>
						<div class="flex h-full w-full items-center bg-app-blue back px-6 py-6 rounded-xl">
							<img :src="benefit.image" :alt="`benefit-${index + 1}`" class="mr-4" />
							<strong class="text-white leading-6">{{ benefit.text }}</strong>
						</div>
					</div>
				</div>
			</div>
			<h2 class="text-lg leading-7 text-white mb-4 text-center">
				{{ $t('subscribeOfferModal.benefitsFooter') }}
				<strong>{{ getLowestPlanInfo }}</strong>
			</h2>
			<div class="pb-4">
				<DefaultPlanCard :plan="plan" :showButton="false" />
			</div>
			<div
				class="floating-width w-auto md:px-4 py-2 sticky bottom-0 md:mb-6 z-20 md:-ml-px bg-app-blue border-t border-radial-1-card md:border-0"
			>
				<DefaultButton @click="handleSubscribe"
					>{{ $t('subscribeOfferModal.subscribeButton') }}
					{{ getLowestPlanInfo }}
				</DefaultButton>
				<DefaultButton @click="onViewPlans" color="transparent">{{
					$t('subscribeOfferModal.viewAllPlans')
				}}</DefaultButton>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

const router = useRouter()
const store = useUserStore()
const { user: loggedUser } = useUserStore()
const { t } = useI18n()

const isCreatingSession = ref<boolean>(false)
const benefits = [
	{
		image: '/web/icons/subscribe-offer-modal/benefit-1.svg',
		text: t('subscribeOfferModal.benefit1'),
		highlight: false,
	},
	{
		image: '/web/icons/subscribe-offer-modal/benefit-2.svg',
		text: t('subscribeOfferModal.benefit2'),
		highlight: false,
	},
	{
		image: '/web/icons/subscribe-offer-modal/benefit-3.svg',
		text: t('subscribeOfferModal.benefit3'),
		highlight: true,
	},
]
const displayModal = ref<boolean>(false)
const defaultDisplayTimerInSeconds = 5
const plan = ref<any>(null)

const closeModal = () => {
	displayModal.value = false
}
const onViewPlans = () => {
	router.push('/checkout/plans')
	displayModal.value = false
}

const handleSubscribe = async () => {
	isCreatingSession.value = true

	$captureEvent(`plans_subscribe_click_${plan.value.englishName.toLowerCase()}`)

	try {
		router.push(`/checkout/payment-select?id=${plan.value._id}`)
		$captureEvent('plans_subscribe_intent')
		// $sendTiktokInitCheckoutEvent(loggedUser);
	} catch (error) {
		$sentryCaptureException(error)
		$captureEvent('plans_subscribe_error')

		$toast({
			type: 'error',
			title: t('common.toastTitle.error'),
			message: t('checkoutPlans.errorToastMessage'),
		})
	}

	isCreatingSession.value = false
}
const updateTimeOfferModal = async () => {
	if (!loggedUser) return

	try {
		await $api('/users/update-time-offer-modal', { method: 'PUT' })

		store.setUser({
			...loggedUser,
			modals: {
				...loggedUser.modals,
				subscriptionOfferModalDisplayedAt: $dayjs().toDate(),
			},
		})
	} catch (error) {
		$sentryCaptureException(error)
	}
}

const getLowestPlanInfo = computed(() => {
	return `${$formatCurrency(plan.value.price)}/${t('common.monthly')}`
})

onMounted(async () => {
	$mitt.on('openSubscribeOfferModal', async () => {
		const plans: any[] = await $api('/plans/', { method: 'GET' })
		plan.value = plans.reduce((prev: any, current: any) => (prev.price < current.price ? prev : current))
		setTimeout(() => {
			displayModal.value = true
			updateTimeOfferModal()
		}, defaultDisplayTimerInSeconds * 1000)
	})
})
</script>

<style scoped>
.border-padding {
	padding: 2px;
}
</style>
